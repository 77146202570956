export enum ApiPaths {
  AUTH = "api/auth",
  USER = "api/user",
  PROJECT = "api/project",
  EVENT = "api/event",
  DEVICE = "api/device",
  DATA = "api/data",
  API_KEY = "api/api-key",
}

export enum UserApiPaths {
  REGISTER = "register",
  LOAD_USER = "load-user",
}

export enum ProjectApiPaths {
  LIST_PROJECTS = "list-projects",
  GET_PROJECT = "get-project",
  CREATE_PROJECT = "create-project",
  DELETE_PROJECT = "delete-project",
  UPDATE_PROJECT = "update-project",
  CREATE_EVENT = "create-event",
  LIST_EVENTS = "list-events",
  DELETE_EVENT = "delete-event",
  UPDATE_EVENT = "update-event",
  LIST_DATA = "list-data",
  COLLECT_DATA = "collect-data",
}

export enum DeviceApiPaths {
  LIST_DEVICES = "list-devices",
  GET_DEVICE = "get-device",
  CREATE_DEVICE = "create-device",
  DELETE_DEVICE = "delete-device",
  UPDATE_DEVICE = "update-device",
}

export enum ApiKeyApiPaths {
  LIST_API_KEYS = "list-api-keys",
  GET_API_KEY = "get-api-key",
  CREATE_API_KEY = "create-api-key",
  DELETE_API_KEY = "delete-api-key",
}
