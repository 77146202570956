import React from "react";
import styles from "./index.module.scss";
import { Button, Card, Form, Input, message } from "antd";
import { useDispatch } from "react-redux";
import { apiAuthLogin } from "../../services/auth";
import { actionUserLogin } from "../../store/actions/user.actions";
import { RouterPaths } from "../../router/paths";
import { Link } from "react-router-dom";
import { apiUserRegister } from "../../services/user";

interface RegisterFormValues {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
}

function RegisterPage() {
  const dispatch = useDispatch<any>();
  const loginCallback = React.useCallback(
    async (values: RegisterFormValues) => {
      if (
        values.email?.length &&
        values.password?.length &&
        values.firstName?.length &&
        values.lastName?.length
      ) {
        const result = await apiUserRegister(values);
        if (result && result.data?.accessToken) {
          dispatch(actionUserLogin({ token: result.data.accessToken }));
          message.success({ content: "Welcome!" });
        } else {
          message.error({ content: "Something went wrong!" });
        }
      } else {
        message.error({ content: "Must not be empty!" });
      }
    },
    []
  );

  return (
    <div className={styles.container}>
      <Card title="Login">
        <Form name="login" onFinish={loginCallback}>
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: "Please input your email!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="First Name"
            name="firstName"
            rules={[
              { required: true, message: "Please input your first name!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Last Name"
            name="lastName"
            rules={[
              { required: true, message: "Please input your last name!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password />
          </Form.Item>
          <div className={styles.actions}>
            <Button type="primary" htmlType="submit">
              Create Account
            </Button>
            <Link to={RouterPaths.LOGIN}>
              <Button type="link">Login</Button>
            </Link>
          </div>
        </Form>
      </Card>
    </div>
  );
}

export default RegisterPage;
