import { useSelector } from "react-redux";
import { RootState } from "../store/reducers/types/root";
import { DefinedUseQueryResult, useQuery } from "@tanstack/react-query";
import { ApiPaths, DeviceApiPaths } from "../services/paths";
import React from "react";
import { ListApiKeysResponse } from "../services/types";
import { AxiosResponse } from "axios";
import { apiListApiKeys } from "../services/api-key";

interface UseApiKeysProps {
  enabled?: boolean;
}

export function useApiKeys({
  enabled = true,
}: UseApiKeysProps): [
  DefinedUseQueryResult<AxiosResponse<ListApiKeysResponse, any> | null, Error>
] {
  const token = useSelector((state: RootState) => state.user.token);
  const data = useQuery({
    queryKey: [ApiPaths.USER, DeviceApiPaths.LIST_DEVICES, token],
    enabled: false,
    initialData: null,
    queryFn: () => apiListApiKeys(token as string),
  });

  React.useEffect(() => {
    if (enabled && token) {
      data.refetch();
    }
  }, [token]);

  return [data];
}
