import React from "react";
import styles from "./index.module.scss";
import ContentWrapper from "../../components/ContentWrapper";
import { Card, Typography } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { RouterPaths } from "../../router/paths";
import { Link } from "react-router-dom";
import ProjectList from "../../components/ProjectList";

function DashboardPage() {
  const quickActions = [
    {
      icon: <PlusCircleOutlined />,
      title: "Create new project",
      href: RouterPaths.CREATE_PROJECT,
    },
    {
      icon: <PlusCircleOutlined />,
      title: "Create new API key",
      href: RouterPaths.API_KEYS,
    },
    {
      icon: <PlusCircleOutlined />,
      title: "Add new device",
      href: RouterPaths.CREATE_DEVICE,
    },
  ];

  return (
    <ContentWrapper>
      <div className={styles.quickActions}>
        <Typography.Title level={2}>Quick Actions</Typography.Title>
        <div className={styles.quickActionsItems}>
          {quickActions.map((action, index) => (
            <Link to={action.href} key={index}>
              <Card className={styles.quickActionsItemCard}>
                <div className={styles.quickActionsItem}>
                  {action.icon}
                  <span>{action.title}</span>
                </div>
              </Card>
            </Link>
          ))}
        </div>
      </div>
      <ProjectList />
    </ContentWrapper>
  );
}

export default DashboardPage;
