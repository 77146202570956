import React from "react";
import styles from "./index.module.scss";
import { Typography } from "antd";

interface ContentHeaderProps {
  title: React.ReactNode;
  actions?: React.ReactNode;
}

function ContentHeader({ title, actions }: ContentHeaderProps) {
  return (
    <div className={styles.container}>
      <Typography.Title level={3} className={styles.title}>
        {title}
      </Typography.Title>
      <div className={styles.actions}>{actions}</div>
    </div>
  );
}

export default ContentHeader;
