import { useSelector } from "react-redux";
import { RootState } from "../store/reducers/types/root";
import { useQuery } from "@tanstack/react-query";
import { ApiPaths, UserApiPaths } from "../services/paths";
import { apiLoadUser } from "../services/user";
import React from "react";

interface UseUserProps {
  enabled?: boolean;
}

export function useUser(props: UseUserProps = { enabled: true }) {
  const token = useSelector((state: RootState) => state.user.token);
  const data = useQuery({
    queryKey: [ApiPaths.USER, UserApiPaths.LOAD_USER, token],
    enabled: false,
    initialData: null,
    queryFn: () => apiLoadUser(token as string),
  });

  React.useEffect(() => {
    if (props.enabled && token) {
      data.refetch();
    }
  }, [token]);

  return data;
}
