export interface User {
  email: string;
  firstName: string;
  lastName: string;
  id: number;
  aliasId: string;
  createdAt: string;
}

export interface Project {
  displayName: string;
  description: string;
  id: number;
  aliasId: string;
  createdAt: string;
}

export interface PaginationRequest {
  offset?: number;
  limit?: number;
}

export interface PaginationResponse {
  total: number;
  offset: number;
  limit: number;
}

export interface AuthLoginRequest {
  email: string;
  password: string;
}

export interface AuthLoginResponse {
  accessToken: string;
}

export interface UserRegisterRequest {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
}

export interface UserRegisterResponse {
  user: User;
  accessToken: string;
}

export interface LoadUserResponse {
  user: User;
}

export interface CreateProjectRequest {
  displayName: string;
  description: string;
}

export interface CreateProjectResponse {
  project: Project;
}

export interface UpdateProjectRequest {
  projectId: string;
  displayName?: string;
  description?: string;
}

export interface UpdateProjectResponse {
  project: Project;
}

export interface DeleteProjectRequest {
  projectIds: string[];
}

export interface DeleteProjectResponse {
  projects: Project[];
}

export interface GetProjectRequest {
  aliasId: string;
}

export interface GetProjectResponse {
  project: Project;
}

export interface ListProjectsRequest {
  pagination?: PaginationRequest;
}

export interface ListProjectResponse {
  projects: Project[];
  pagination: PaginationResponse;
}

export enum EventDataType {
  NUMBER = "number",
  STRING = "string",
}

export interface Event {
  displayName: string;
  description: string;
  id: number;
  aliasId: string;
  createdAt: string;
  type: EventDataType;
}

export interface CreateEventRequest {
  projectId: string;
  displayName: string;
  description: string;
  type: EventDataType;
}

export interface CreateEventResponse {
  event: Event;
}

export interface ListEventsRequest {
  projectId?: string;
}

export interface ListEventsResponse {
  events: Event[];
}

export interface DeleteEventsRequest {
  eventIds: string[];
}

export interface DeleteEventsResponse {
  events: Event[];
}

export interface UpdateEventRequest {
  eventId: string;
  displayName?: string;
  description?: string;
  type?: EventDataType;
}

export interface UpdateEventResponse {
  event: Event;
}

export interface Device {
  id: number;
  aliasId: string;
  displayName: string;
  description: string;
  createdAt: string;
  user?: User;
}

export interface Data {
  id: number;
  aliasId: string;
  createdAt: string;
  data: string;
  event?: Event;
  device?: Device;
}

export interface ApiKey {
  id: number;
  aliasId: string;
  createdAt: string;
  displayName: string;
  description: string;
  user?: User;
}

export interface ListDataRequest {
  eventId: string;
  startDate?: string;
  endDate?: string;
  pagination?: PaginationRequest;
}

export interface ListDataResponse {
  data: Data[];
  pagination: PaginationResponse;
  startDate: string;
  endDate: string;
}

export interface CollectDataRequest {
  apiKey: string;
  eventId: string;
  deviceId: string;
  data: string | number;
}

export interface CollectDataResponse {
  success: boolean;
}

export interface ListDevicesRequest {
  pagination: PaginationRequest;
}

export interface ListDevicesResponse {
  devices: Device[];
  pagination: PaginationResponse;
}

export interface GetDeviceRequest {
  deviceId: string;
}

export interface GetDeviceResponse {
  device: Device;
}

export interface CreateDeviceRequest {
  displayName: string;
  description: string;
}

export interface CreateDeviceResponse {
  device: Device;
}

export interface DeleteDeviceRequest {
  deviceIds: string[];
}

export interface DeleteDeviceResponse {
  devices: Device[];
}

export interface UpdateDeviceRequest {
  deviceId: string;
  displayName?: string;
  description?: string;
}

export interface UpdateDeviceResponse {
  device: Device;
}

export interface ListApiKeysResponse {
  apiKeys: ApiKey[];
}

export interface GetApiKeyRequest {
  apiKeyId: string;
}

export interface GetApiKeyResponse {
  apiKey: ApiKey;
}

export interface CreateApiKeyRequest {
  displayName: string;
  description: string;
}

export interface CreatedApiKey {
  aliasId: string;
  apiKey: string;
  displayName: string;
  description: string;
}

export interface CreateApiKeyResponse {
  apiKey: CreatedApiKey;
}

export interface DeleteApiKeyRequest {
  apiKeyIds: string[];
}

export interface DeleteApiKeyResponse {
  apiKeys: ApiKey[];
}
