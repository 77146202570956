import API from "./axios";
import { UserApiPaths } from "./paths";
import {
  LoadUserResponse,
  UserRegisterRequest,
  UserRegisterResponse,
} from "./types";

export function apiUserRegister(body: UserRegisterRequest) {
  return API.post<UserRegisterResponse>(`user/${UserApiPaths.REGISTER}`, body);
}

export function apiLoadUser(token: string) {
  return API.get<LoadUserResponse>(`user/${UserApiPaths.LOAD_USER}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
