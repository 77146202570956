import React from "react";
import styles from "./index.module.scss";
import { Button, Card, Form, Input, message } from "antd";
import { useDispatch } from "react-redux";
import { apiAuthLogin } from "../../services/auth";
import { actionUserLogin } from "../../store/actions/user.actions";
import { RouterPaths } from "../../router/paths";
import { Link } from "react-router-dom";

function LoginPage() {
  const dispatch = useDispatch<any>();
  const loginCallback = React.useCallback(async (values: any) => {
    if (values.email?.length && values.password?.length) {
      const result = await apiAuthLogin(values);
      if (result && result.data?.accessToken) {
        dispatch(actionUserLogin({ token: result.data.accessToken }));
        message.success({ content: "Logged in!" });
      } else {
        message.error({ content: "Incorrect login!" });
      }
    } else {
      message.error({ content: "Must not be empty!" });
    }
  }, []);

  return (
    <div className={styles.container}>
      <Card title="Login">
        <Form name="login" onFinish={loginCallback}>
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: "Please input your email!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password />
          </Form.Item>
          <div className={styles.actions}>
            <Button type="primary" htmlType="submit">
              Login
            </Button>
            <Link to={RouterPaths.REGISTER}>
              <Button type="link">Register</Button>
            </Link>
          </div>
        </Form>
      </Card>
    </div>
  );
}

export default LoginPage;
