import { Layout } from "antd";
import { Content } from "antd/es/layout/layout";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import { selectUserState } from "../store/selectors/user";
import { RouterPaths } from "./paths";
import RegisterPage from "../pages/Register";
import LoginPage from "../pages/Login";
import DashboardPage from "../pages/Dashboard";
import LeftSidebar from "../components/LeftSidebar";
import Header from "../components/Header";
import ProjectsPage from "../pages/Projects";
import SingleProjectPage from "../pages/SingleProject";
import DevicesPage from "../pages/Devices";
import CreateDevicePage from "../pages/CreateDevice";
import APIKeysPage from "../pages/ApiKeys";
import CreateProjectPage from "../pages/CreateProject";
import { apiAuthRefreshToken } from "../services/auth";
import {
  actionUserLogin,
  actionUserLogout,
} from "../store/actions/user.actions";
import CreateAPIKeyPage from "../pages/CreateAPIKey";
import SingleEventPage from "../pages/SingleEvent";

function AppRouter() {
  const { isLoggedIn, token } = useSelector(selectUserState);
  const dispatch = useDispatch<any>();

  React.useEffect(() => {
    if (isLoggedIn && token) {
      apiAuthRefreshToken(token).then((res) => {
        if (res && res.data?.accessToken) {
          dispatch(actionUserLogin({ token: res.data.accessToken }));
        } else if (res.status === 401) {
          dispatch(actionUserLogout());
        }
      });
    }
  }, [isLoggedIn]);

  return (
    <BrowserRouter>
      {!isLoggedIn ? (
        <Routes>
          <Route path={RouterPaths.LOGIN} element={<LoginPage />} />
          <Route path={RouterPaths.REGISTER} element={<RegisterPage />} />
          <Route path="*" element={<Navigate to={RouterPaths.LOGIN} />} />
        </Routes>
      ) : (
        <Layout style={{ minHeight: "100vh" }}>
          <Header />
          <Layout className="site-layout">
            <LeftSidebar />
            <Content>
              <Routes>
                <Route
                  path={RouterPaths.DASHBOARD}
                  element={<DashboardPage />}
                />
                <Route path={RouterPaths.PROJECTS} element={<ProjectsPage />} />
                <Route
                  path={`${RouterPaths.PROJECTS}/:projectId`}
                  element={<SingleProjectPage />}
                />
                <Route
                  path={`${RouterPaths.PROJECTS}/:projectId/events/:eventId`}
                  element={<SingleEventPage />}
                />
                <Route
                  path={RouterPaths.CREATE_PROJECT}
                  element={<CreateProjectPage />}
                />
                <Route path={RouterPaths.DEVICES} element={<DevicesPage />} />
                <Route
                  path={RouterPaths.CREATE_DEVICE}
                  element={<CreateDevicePage />}
                />
                <Route path={RouterPaths.API_KEYS} element={<APIKeysPage />} />
                <Route
                  path={RouterPaths.CREATE_API_KEY}
                  element={<CreateAPIKeyPage />}
                />
                <Route
                  path="*"
                  element={<Navigate to={RouterPaths.DASHBOARD} />}
                />
              </Routes>
            </Content>
          </Layout>
        </Layout>
      )}
    </BrowserRouter>
  );
}

export default AppRouter;
