import React from "react";
import styles from "./index.module.scss";
import ContentWrapper from "../../components/ContentWrapper";
import ProjectList from "../../components/ProjectList";
import ContentHeader from "../../components/ContentHeader";
import { Link } from "react-router-dom";
import { RouterPaths } from "../../router/paths";
import { Button } from "antd";

function ProjectsPage() {
  return (
    <ContentWrapper>
      <ProjectList />
    </ContentWrapper>
  );
}

export default ProjectsPage;
