import API from "./axios";
import { ApiKeyApiPaths } from "./paths";
import {
  CreateApiKeyRequest,
  CreateApiKeyResponse,
  DeleteApiKeyRequest,
  DeleteApiKeyResponse,
  GetApiKeyRequest,
  GetApiKeyResponse,
  ListApiKeysResponse,
} from "./types";

export function apiListApiKeys(token: string) {
  return API.post<ListApiKeysResponse>(
    `api-key/${ApiKeyApiPaths.LIST_API_KEYS}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

export function apiGetApiKey(token: string, body: GetApiKeyRequest) {
  return API.post<GetApiKeyResponse>(
    `api-key/${ApiKeyApiPaths.LIST_API_KEYS}`,
    body,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

export function apiCreateApiKey(token: string, body: CreateApiKeyRequest) {
  return API.post<CreateApiKeyResponse>(
    `api-key/${ApiKeyApiPaths.CREATE_API_KEY}`,
    body,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

export function apiDeleteApiKey(token: string, body: DeleteApiKeyRequest) {
  return API.post<DeleteApiKeyResponse>(
    `api-key/${ApiKeyApiPaths.DELETE_API_KEY}`,
    body,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
}
