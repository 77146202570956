import API from "./axios";
import { AuthLoginRequest, AuthLoginResponse } from "./types";

export function apiAuthLogin(body: AuthLoginRequest) {
  return API.post<AuthLoginResponse>(`auth/login`, body);
}

export function apiAuthRefreshToken(token: string) {
  return API.post<AuthLoginResponse>(
    `auth/refresh-token`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
}
