import React from "react";
import styles from "./index.module.scss";
import ContentWrapper from "../../components/ContentWrapper";
import {
  Button,
  Card,
  Dropdown,
  MenuProps,
  Table,
  Typography,
  message,
} from "antd";
import ContentHeader from "../../components/ContentHeader";
import { Link, useNavigate } from "react-router-dom";
import { RouterPaths } from "../../router/paths";
import { useSelector } from "react-redux";
import { selectUserState } from "../../store/selectors/user";
import { useEventsList } from "../../hooks/use-events";
import { apiDeleteEvents } from "../../services/project";
import CreateEventModal from "../CreateEventModal";

interface EventsListProps {
  projectId: string;
}

function EventsList({ projectId }: EventsListProps) {
  const [events] = useEventsList({
    defaultBody: { projectId },
  });
  const { token } = useSelector(selectUserState);
  const [selectedEvents, setSelectedEvents] = React.useState<React.Key[]>([]);
  const [actionLoading, setActionLoading] = React.useState(false);
  const [createOpen, setCreateOpen] = React.useState(false);

  const columns: any = [
    {
      title: "Name",
      dataIndex: "displayName",
      key: "displayName",
      render: (v: string, r: any) => (
        <Link to={`${RouterPaths.PROJECTS}/${projectId}/events/${r.aliasId}`}>
          {v}
        </Link>
      ),
    },
    {
      title: "Data Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (v: any) => new Date(v).toLocaleString(),
    },
    {
      title: "ID",
      dataIndex: "aliasId",
      key: "aliasId",
    },
  ];

  const selected = selectedEvents.length > 0;
  const disabled = actionLoading || !selected;
  const isLoading =
    (!events.data?.data?.events && events.isLoading) || actionLoading;

  const actions: MenuProps["items"] = [
    {
      key: "Delete",
      label: "Delete",
      onClick: async () => {
        setActionLoading(true);
        if (token && selectedEvents) {
          const res = await apiDeleteEvents(token, {
            eventIds: selectedEvents as string[],
          });
          if (res.status === 201) {
            events.refetch();
            message.success("Events deleted successfully!");
          } else {
            message.error("Failed to delete events!");
          }
        }
        setActionLoading(false);
      },
      disabled: actionLoading || !selected,
    },
  ];

  return (
    <>
      <ContentHeader
        title="Project Events"
        actions={
          <>
            <Button
              style={{ marginRight: "8px" }}
              type="primary"
              onClick={() => setCreateOpen(true)}
            >
              Create new event
            </Button>
            <Dropdown menu={{ items: actions }} placement="bottomRight">
              <Button disabled={disabled}>Actions</Button>
            </Dropdown>
          </>
        }
      />
      <Table
        columns={columns}
        dataSource={events.data?.data?.events?.map((d) => ({
          key: d.aliasId,
          ...d,
        }))}
        rowSelection={{
          selectedRowKeys: selectedEvents,
          onChange: (keys) => setSelectedEvents(keys),
        }}
        loading={isLoading}
        pagination={false}
      />
      <CreateEventModal
        projectId={projectId}
        onClose={() => {
          events.refetch();
          setCreateOpen(false);
        }}
        isActive={createOpen}
      />
    </>
  );
}

export default EventsList;
