import React from "react";
import ContentWrapper from "../../components/ContentWrapper";
import { Button, Card, DatePicker, Modal, Table, Typography } from "antd";
import ContentHeader from "../../components/ContentHeader";
import { useParams } from "react-router-dom";
import { useEventData } from "../../hooks/use-event-data";
import { useEventsList } from "../../hooks/use-events";
import { Data, Event, EventDataType } from "../../services/types";
import { RedoOutlined } from "@ant-design/icons";
import moment from "moment";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { selectUserState } from "../../store/selectors/user";

function generateCodeSnippet(event: Event, deviceId: string) {
  return `curl --location '${
    process.env.REACT_APP_API
  }/api/project/collect-data' \
--header 'Content-Type: application/json' \
--data '{
      "apiKey": "REPLACE_WITH_YOUR_API_KEY",
      "eventId": "${event.aliasId}",
      "deviceId": "${deviceId}",
      "data": ${event.type === EventDataType.NUMBER ? 1 : '"hello world"'}
}'`;
}

function generateESP32CodeSnippet(event: Event, deviceId: string) {
  return `#include <WiFi.h>
  #include <HTTPClient.h>
  
  const char* ssid = "yourSSID";
  const char* password = "yourPassword";
  
  void setup() {
    Serial.begin(115200);
    // Connect to Wi-Fi
    WiFi.begin(ssid, password);
    while (WiFi.status() != WL_CONNECTED) {
      delay(1000);
      Serial.println("Connecting to WiFi..");
    }
    // Once connected, send data
    sendCollectedData();
  }
  
  void sendCollectedData() {
    // Your API endpoint
    String serverName = "${process.env.REACT_APP_API}/api/project/collect-data";
    
    // JSON payload
    String httpRequestData = "{\"apiKey\":\"REPLACE_YOUR_API_KEY\",\"eventId\":\"${
      event.aliasId
    }\",\"deviceId\":\"${deviceId}\",\"data\":${
    event.type === EventDataType.NUMBER ? 1 : '"hello world"'
  }}";
    
    HTTPClient http;
    http.begin(serverName);
    http.addHeader("Content-Type", "application/json");
    
    // Send HTTP POST request
    int httpResponseCode = http.POST(httpRequestData);
    
    if (httpResponseCode == 201) {
      Serial.println("Request Accepted");
    } else {
      Serial.print("Error on sending POST: ");
      Serial.println(httpResponseCode);
    }
    
    // Free resources
    http.end();
  }
  
  void loop() {
    // Nothing to do here
  }`;
}

function SingleEventPage() {
  const params = useParams();
  const [data, setdataRequest] = useEventData({
    defaultBody: {
      pagination: { limit: 100, offset: 0 },
      eventId: params.eventId as string,
      startDate: new Date(
        new Date().getTime() - 1000 * 60 * 60 * 24 * 7
      ).toISOString(),
      endDate: new Date().toISOString(),
    },
  });
  const [events] = useEventsList({
    defaultBody: { projectId: params.projectId as string },
  });
  const [showCode, setShowCode] = React.useState(false);
  const { token } = useSelector(selectUserState);

  const columns: any = [
    {
      title: "Value",
      dataIndex: "data",
      key: "data",
      render: (v: any, r: Data) =>
        r.event?.type === EventDataType.NUMBER ? parseFloat(v) : v,
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (v: any) => new Date(v).toLocaleString(),
    },
    {
      title: "Event Name",
      dataIndex: "event",
      key: "event.displayName",
      render: (v: any) => v.displayName,
    },
    {
      title: "Device Name",
      dataIndex: "device",
      key: "device.displayName",
      render: (v: any) => v.displayName,
    },
    {
      title: "ID",
      dataIndex: "aliasId",
      key: "aliasId",
    },
    {
      title: "Device ID",
      dataIndex: "device",
      key: "device.aliasId",
      render: (v: any) => v.aliasId,
    },
  ];

  const isLoading = !data.data?.data?.data && data.isLoading;
  const foundEvent = events.data?.data?.events?.find(
    (e) => e.aliasId === params.eventId
  );

  return (
    <ContentWrapper>
      <Typography.Title level={2}>
        Event{" "}
        {events.isLoading || !foundEvent
          ? "Loading..."
          : foundEvent.displayName}
      </Typography.Title>
      <Card>
        <ContentHeader
          title="Data Collected"
          actions={
            <>
              <DatePicker.RangePicker
                onChange={(dates) => {
                  setdataRequest({
                    eventId: params.eventId as string,
                    startDate: dates?.[0]?.toISOString(),
                    endDate: dates?.[1]?.toISOString(),
                  });
                }}
                defaultValue={[
                  dayjs(new Date()).subtract(7, "day"),
                  dayjs(new Date()),
                ]}
                style={{ marginRight: "8px" }}
              />
              <Button
                onClick={() => {
                  window.open(
                    `${process.env.REACT_APP_API}/api/project/export-data?eventId=${params.eventId}&accessToken=${token}`,
                    "_blank"
                  );
                }}
                style={{ marginRight: "8px" }}
              >
                Export data
              </Button>
              <Button
                style={{ marginRight: "8px" }}
                onClick={() => data.refetch()}
              >
                <RedoOutlined />
                Refresh
              </Button>
              <Button onClick={() => setShowCode(true)}>Show Code</Button>
            </>
          }
        />
        <Table
          columns={columns}
          dataSource={data.data?.data?.data?.map((d) => ({
            key: d.aliasId,
            ...d,
          }))}
          loading={isLoading}
          onChange={(pagination) => {
            setdataRequest({
              eventId: params.eventId as string,
              pagination: {
                limit: pagination.pageSize,
                offset: pagination?.current
                  ? (pagination.current - 1) * (pagination?.pageSize || 10)
                  : 0,
              },
            });
          }}
        />
      </Card>
      {foundEvent ? (
        <Modal
          visible={showCode}
          onCancel={() => setShowCode(false)}
          onOk={() => setShowCode(false)}
        >
          <Typography.Paragraph>
            Curl: You can use this command in terminal to send data to the
            event.
          </Typography.Paragraph>
          <Typography.Paragraph
            copyable={{
              text: generateCodeSnippet(
                foundEvent,
                "REPLACE_WITH_YOUR_DEVICE_ID"
              ) as string,
            }}
          >
            <pre>
              {generateCodeSnippet(foundEvent, "REPLACE_WITH_YOUR_DEVICE_ID")}
            </pre>
          </Typography.Paragraph>
          <Typography.Paragraph>
            ESP32: You can use this command in terminal to send data to the
            event.
          </Typography.Paragraph>
          <Typography.Paragraph
            copyable={{
              text: generateESP32CodeSnippet(
                foundEvent,
                "REPLACE_WITH_YOUR_DEVICE_ID"
              ) as string,
            }}
          >
            <pre>
              {generateESP32CodeSnippet(
                foundEvent,
                "REPLACE_WITH_YOUR_DEVICE_ID"
              )}
            </pre>
          </Typography.Paragraph>
        </Modal>
      ) : null}
    </ContentWrapper>
  );
}

export default SingleEventPage;
