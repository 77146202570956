import { useSelector } from "react-redux";
import { RootState } from "../store/reducers/types/root";
import { DefinedUseQueryResult, useQuery } from "@tanstack/react-query";
import { ApiPaths, UserApiPaths } from "../services/paths";
import { apiLoadUser } from "../services/user";
import React from "react";
import { ListProjectResponse, ListProjectsRequest } from "../services/types";
import { apiListProjects } from "../services/project";
import { AxiosResponse } from "axios";

interface UseProjectListProps {
  enabled?: boolean;
  defaultBody?: ListProjectsRequest;
}

export function useProjectList({
  enabled = true,
  defaultBody = {},
}: UseProjectListProps): [
  DefinedUseQueryResult<AxiosResponse<ListProjectResponse, any> | null, Error>,
  React.Dispatch<React.SetStateAction<ListProjectsRequest>>
] {
  const token = useSelector((state: RootState) => state.user.token);
  const [body, setBody] = React.useState<ListProjectsRequest>(defaultBody);
  const data = useQuery({
    queryKey: [ApiPaths.USER, UserApiPaths.LOAD_USER, token, body],
    enabled: false,
    initialData: null,
    queryFn: () => apiListProjects(token as string, body),
  });

  React.useEffect(() => {
    if (enabled && token) {
      data.refetch();
    }
  }, [token, body]);

  return [data, setBody];
}
