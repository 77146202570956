import React, { useLayoutEffect } from "react";
import styles from "./index.module.scss";
import ContentWrapper from "../../components/ContentWrapper";
import { Button, Card, Form, Input, Spin, Typography, message } from "antd";
import { useProject } from "../../hooks/use-project";
import { useNavigate, useParams } from "react-router";
import { RouterPaths } from "../../router/paths";
import TextArea from "antd/es/input/TextArea";
import { UpdateProjectRequest } from "../../services/types";
import { apiDeleteProject, apiUpdateProject } from "../../services/project";
import { useSelector } from "react-redux";
import { selectUserState } from "../../store/selectors/user";
import ContentHeader from "../../components/ContentHeader";
import EventsList from "../../components/EventsList";

interface UpdateProjectFormValues {
  displayName?: string;
  description?: string;
}

function SingleProjectPage() {
  const params = useParams();
  const navigate = useNavigate();
  const { token } = useSelector(selectUserState);
  const [project] = useProject({
    defaultBody: { aliasId: params.projectId as string },
  });

  const onFinish = async (values: UpdateProjectFormValues) => {
    if (project?.data?.data?.project?.aliasId && token) {
      const req: UpdateProjectRequest = {
        projectId: project?.data?.data?.project?.aliasId,
      };

      if (values.displayName !== project?.data?.data?.project?.displayName) {
        req.displayName = values.displayName;
      }

      if (values.description !== project?.data?.data?.project?.description) {
        req.description = values.description;
      }

      if (Object.keys(req).length > 1) {
        const res = await apiUpdateProject(token, req);
        if (res.status === 201) {
          project.refetch();
          message.success("Project updated!");
        } else {
          message.error("Failed to update project!");
        }
      }
    }
  };

  const deleteProject = async () => {
    if (token && project?.data?.data?.project?.aliasId) {
      const res = await apiDeleteProject(token, {
        projectIds: [project?.data?.data?.project?.aliasId],
      });

      if (res.status === 201) {
        navigate(RouterPaths.PROJECTS);
        message.success("Project deleted!");
      } else {
        message.error("Failed to delete project!");
      }
    }
  };

  useLayoutEffect(() => {
    if (!params.projectId) {
      navigate(RouterPaths.PROJECTS);
      message.error("Project not found!");
    }
  }, []);

  const loading =
    !project.data?.data?.project?.aliasId &&
    (project.isLoading || project.isError);

  return (
    <ContentWrapper>
      <div className={styles.container}>
        <Typography.Title level={2}>
          {loading ? "Loading..." : project.data?.data?.project?.displayName}
        </Typography.Title>
        <Card className={styles.content}>
          {loading || !project?.data?.data?.project?.aliasId ? (
            <div className={styles.loader}>
              <Spin />
            </div>
          ) : (
            <>
              <ContentHeader
                title="Project Details"
                actions={
                  <>
                    <Button danger type="primary" onClick={deleteProject}>
                      Delete Project
                    </Button>
                  </>
                }
              />
              <Form
                className={styles.updateForm}
                name="updateProject"
                onFinish={onFinish}
              >
                <Form.Item
                  label="Display Name"
                  name="displayName"
                  initialValue={project.data?.data?.project?.displayName}
                  labelCol={{ span: 24 }}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Description"
                  name="description"
                  initialValue={project.data?.data?.project?.description}
                  labelCol={{ span: 24 }}
                >
                  <TextArea style={{ resize: "none" }} />
                </Form.Item>
                <Button type="primary" htmlType="submit">
                  Update
                </Button>
              </Form>
              <EventsList projectId={params.projectId as string} />
            </>
          )}
        </Card>
      </div>
    </ContentWrapper>
  );
}

export default SingleProjectPage;
