import { useSelector } from "react-redux";
import { RootState } from "../store/reducers/types/root";
import { DefinedUseQueryResult, useQuery } from "@tanstack/react-query";
import { ApiPaths, ProjectApiPaths } from "../services/paths";
import React from "react";
import { ListDataRequest, ListDataResponse } from "../services/types";
import { AxiosResponse } from "axios";
import { apiListData } from "../services/project";

interface UseEventDataProps {
  enabled?: boolean;
  defaultBody: ListDataRequest;
}

export function useEventData({
  enabled = true,
  defaultBody,
}: UseEventDataProps): [
  DefinedUseQueryResult<AxiosResponse<ListDataResponse, any> | null, Error>,
  React.Dispatch<React.SetStateAction<ListDataRequest>>
] {
  const token = useSelector((state: RootState) => state.user.token);
  const [body, setBody] = React.useState<ListDataRequest>(defaultBody);
  const data = useQuery({
    queryKey: [ApiPaths.PROJECT, ProjectApiPaths.LIST_DATA, token, body],
    enabled: false,
    initialData: null,
    queryFn: () => apiListData(token as string, body),
  });

  React.useEffect(() => {
    if (enabled && token) {
      data.refetch();
    }
  }, [token, body]);

  return [data, setBody];
}
