import React from "react";
import styles from "./index.module.scss";
import { useUser } from "../../hooks/use-user";
import { Avatar, Dropdown, Layout, MenuProps } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { actionUserLogout } from "../../store/actions/user.actions";

function Header() {
  const user = useUser();
  const dispatch = useDispatch<any>();

  const profileItems: MenuProps["items"] = [
    {
      key: "logout",
      label: "Logout",
      onClick: () => {
        dispatch(actionUserLogout());
      },
    },
  ];

  return (
    <Layout.Header className={styles.container}>
      <div className={styles.content}>
        <div className={styles.logo}>Lozancic Zavrsni</div>
        <div className={styles.right}>
          <span className={styles.profileName}>
            Welcome back
            <br />
            <strong>
              {user.data?.data.user?.firstName} {user.data?.data.user?.lastName}
            </strong>
          </span>
          <Dropdown
            menu={{
              items: profileItems,
            }}
            placement="bottomRight"
          >
            <Avatar className={styles.profile} icon={<UserOutlined />} />
          </Dropdown>
        </div>
      </div>
    </Layout.Header>
  );
}

export default Header;
