import React from "react";
import styles from "./index.module.scss";
import ContentWrapper from "../../components/ContentWrapper";
import { Button, Card, Form, Input, Typography, message } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useSelector } from "react-redux";
import { selectUserState } from "../../store/selectors/user";
import { apiCreateProject } from "../../services/project";
import { useNavigate } from "react-router";
import { RouterPaths } from "../../router/paths";
import { apiCreateDevice } from "../../services/device";

interface CreateDeviceFormValues {
  displayName: string;
  description: string;
}

function CreateDevicePage() {
  const { token } = useSelector(selectUserState);
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const onSubmit = React.useCallback(
    async (values: CreateDeviceFormValues) => {
      setLoading(true);
      if (token && values.displayName?.length) {
        const res = await apiCreateDevice(token, {
          displayName: values.displayName,
          description: values.description || "",
        });

        if (res.status === 201) {
          message.success("Device created successfully!");
          navigate(RouterPaths.DEVICES);
        } else {
          message.error("Failed to create Device!");
        }
      }
      setLoading(false);
    },
    [token]
  );

  return (
    <ContentWrapper>
      <Typography.Title level={2}>Add new device</Typography.Title>
      <Card>
        <Form
          name="createDevice"
          className={styles.form}
          onFinish={onSubmit}
          disabled={loading}
        >
          <Form.Item
            label="Display Name"
            name="displayName"
            rules={[{ required: true, message: "Please input display name!" }]}
            labelCol={{ span: 24 }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Description"
            name="description"
            labelCol={{ span: 24 }}
          >
            <TextArea style={{ resize: "none" }} />
          </Form.Item>
          <Form.Item labelCol={{ span: 24 }}>
            <Button type="primary" htmlType="submit">
              Create Device
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </ContentWrapper>
  );
}

export default CreateDevicePage;
