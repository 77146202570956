import { createLogger } from "redux-logger";
import { persistStore } from "redux-persist";
import rootReducer from "./reducers/root.reducer";
import { configureStore } from "@reduxjs/toolkit";

const persistedReducer = rootReducer;

const reduxLogger = createLogger({
  collapsed: true,
  // logger: null,
});

function createStore() {
  const store = configureStore({
    reducer: persistedReducer,
    middleware: (gdm) => gdm().concat([reduxLogger]),
  });
  const persistor = persistStore(store as any);

  return { store, persistor };
}

export default createStore;
