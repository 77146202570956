export enum RouterPaths {
  DASHBOARD = "/dashboard",
  LOGIN = "/login",
  REGISTER = "/register",
  CREATE_PROJECT = "/create-project",
  PROJECTS = "/projects",
  CREATE_DEVICE = "/create-device",
  DEVICES = "/devices",
  API_KEYS = "/api-keys",
  CREATE_API_KEY = "/create-api-key",
}
