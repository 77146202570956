import API from "./axios";
import { ProjectApiPaths } from "./paths";
import {
  CollectDataRequest,
  CollectDataResponse,
  CreateEventRequest,
  CreateEventResponse,
  CreateProjectRequest,
  CreateProjectResponse,
  DeleteEventsRequest,
  DeleteEventsResponse,
  DeleteProjectRequest,
  DeleteProjectResponse,
  GetProjectRequest,
  GetProjectResponse,
  ListDataRequest,
  ListDataResponse,
  ListEventsRequest,
  ListEventsResponse,
  ListProjectResponse,
  ListProjectsRequest,
  UpdateEventRequest,
  UpdateEventResponse,
  UpdateProjectRequest,
  UpdateProjectResponse,
} from "./types";

export function apiListProjects(token: string, body: ListProjectsRequest) {
  return API.post<ListProjectResponse>(
    `project/${ProjectApiPaths.LIST_PROJECTS}`,
    body,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

export function apiCreateProject(token: string, body: CreateProjectRequest) {
  return API.post<CreateProjectResponse>(
    `project/${ProjectApiPaths.CREATE_PROJECT}`,
    body,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

export function apiGetProject(token: string, body: GetProjectRequest) {
  return API.post<GetProjectResponse>(
    `project/${ProjectApiPaths.GET_PROJECT}`,
    body,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

export function apiDeleteProject(token: string, body: DeleteProjectRequest) {
  return API.post<DeleteProjectResponse>(
    `project/${ProjectApiPaths.DELETE_PROJECT}`,
    body,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

export function apiUpdateProject(token: string, body: UpdateProjectRequest) {
  return API.post<UpdateProjectResponse>(
    `project/${ProjectApiPaths.UPDATE_PROJECT}`,
    body,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

export function apiCreateEvent(token: string, body: CreateEventRequest) {
  return API.post<CreateEventResponse>(
    `project/${ProjectApiPaths.CREATE_EVENT}`,
    body,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

export function apiListEvents(token: string, body: ListEventsRequest) {
  return API.post<ListEventsResponse>(
    `project/${ProjectApiPaths.LIST_EVENTS}`,
    body,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

export function apiDeleteEvents(token: string, body: DeleteEventsRequest) {
  return API.post<DeleteEventsResponse>(
    `project/${ProjectApiPaths.DELETE_EVENT}`,
    body,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

export function apiUpdateEvent(token: string, body: UpdateEventRequest) {
  return API.post<UpdateEventResponse>(
    `project/${ProjectApiPaths.UPDATE_EVENT}`,
    body,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

export function apiListData(token: string, body: ListDataRequest) {
  return API.post<ListDataResponse>(
    `project/${ProjectApiPaths.LIST_DATA}`,
    body,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

export function apiCollectData(token: string, body: CollectDataRequest) {
  return API.post<CollectDataResponse>(
    `project/${ProjectApiPaths.COLLECT_DATA}`,
    body,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
}
