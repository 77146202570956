import React from "react";
import styles from "./index.module.scss";
import { useProjectList } from "../../hooks/use-project-list";
import { Button, Card, Empty, Pagination, Spin, Typography } from "antd";
import Meta from "antd/es/card/Meta";
import randomColor from "randomcolor";
import { useNavigate } from "react-router";
import { RouterPaths } from "../../router/paths";
import ContentHeader from "../ContentHeader";
import { Link } from "react-router-dom";

interface ProjectListProps {
  pagination?: boolean;
  showOnly?: number;
}

function ProjectList({ pagination = true, showOnly }: ProjectListProps) {
  const navigate = useNavigate();
  const [projects, setRequest] = useProjectList({
    defaultBody: { pagination: { offset: 0, limit: 6 } },
  });
  const isLoading = !projects.data?.data?.projects && projects.isLoading;

  return (
    <div className={styles.container}>
      <Typography.Title level={2}>Projects</Typography.Title>
      <Card>
        {isLoading ? (
          <div className={styles.loader}>
            <Spin />
          </div>
        ) : (
          <>
            <ContentHeader
              title="List of all projects"
              actions={
                <>
                  <Link to={RouterPaths.CREATE_PROJECT}>
                    <Button type="primary">Create New Project</Button>
                  </Link>
                </>
              }
            />
            <div className={styles.projects}>
              {projects.data?.data?.projects?.map((project) => (
                <Card
                  onClick={() => {
                    navigate(`${RouterPaths.PROJECTS}/${project.aliasId}`);
                  }}
                  className={styles.project}
                  key={project.aliasId}
                  cover={
                    <div
                      className={styles.projectCover}
                      style={{
                        backgroundColor: randomColor({ seed: project.aliasId }),
                      }}
                    />
                  }
                >
                  <Meta
                    // avatar={<Avatar src="https://api.dicebear.com/7.x/miniavs/svg?seed=8" />}
                    title={project.displayName}
                    description={project.description}
                  />
                </Card>
              ))}
              {projects.data?.data?.projects?.length === 0 ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "500px",
                  }}
                >
                  <Empty />
                </div>
              ) : null}
            </div>
            <div className={styles.footer}>
              <Pagination
                defaultCurrent={1}
                defaultPageSize={6}
                onChange={(page) => {
                  setRequest({
                    pagination: {
                      limit: 6,
                      offset: (page - 1) * 6,
                    },
                  });
                }}
                total={projects.data?.data?.pagination?.total}
              />
            </div>
          </>
        )}
      </Card>
    </div>
  );
}

export default ProjectList;
