import { Button, Form, Input, Modal, Select, message } from "antd";
import TextArea from "antd/es/input/TextArea";
import { apiCreateEvent } from "../../services/project";
import { CreateEventRequest, EventDataType } from "../../services/types";
import { useSelector } from "react-redux";
import { selectUserState } from "../../store/selectors/user";
import React from "react";

interface CreateEventModalProps {
  projectId: string;
  isActive: boolean;
  onClose: () => void;
}

interface CreateEventFormValues {
  displayName: string;
  description: string;
  type: EventDataType;
}

function CreateEventModal({
  projectId,
  isActive,
  onClose,
}: CreateEventModalProps) {
  const { token } = useSelector(selectUserState);
  const [loading, setLoading] = React.useState(false);

  const onFinish = async (values: CreateEventFormValues) => {
    setLoading(true);
    if (token && values.displayName?.length && values.type) {
      const req: CreateEventRequest = {
        projectId,
        displayName: values.displayName,
        description: values.description ?? undefined,
        type: values.type,
      };
      const res = await apiCreateEvent(token, req);
      if (res.status === 201) {
        message.success("Event created successfully!");
        onClose();
      } else {
        message.error("Failed to create Event!");
        onClose();
      }
    }
    setLoading(false);
  };

  return (
    <Modal
      title="Create Event"
      open={isActive}
      onCancel={onClose}
      footer={null}
    >
      <Form name="createEvent" onFinish={onFinish} disabled={loading}>
        <Form.Item
          label="Display Name"
          name="displayName"
          rules={[{ required: true, message: "Please input display name!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Data Type"
          name="type"
          rules={[{ required: true, message: "Please choose data type!" }]}
          initialValue={EventDataType.STRING}
        >
          <Select placeholder="Select a type">
            {Object.values(EventDataType).map((type) => (
              <Select.Option key={type} value={type}>
                {type}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Description" name="description">
          <TextArea style={{ resize: "none" }} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Create Event
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default CreateEventModal;
