import React from "react";
import styles from "./index.module.scss";
import ContentWrapper from "../../components/ContentWrapper";
import { Button, Card, Form, Input, Modal, Typography, message } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useSelector } from "react-redux";
import { selectUserState } from "../../store/selectors/user";
import { useNavigate } from "react-router";
import { RouterPaths } from "../../router/paths";
import { apiCreateApiKey } from "../../services/api-key";
import Paragraph from "antd/es/skeleton/Paragraph";

interface CreateAPIKeyFormValues {
  displayName: string;
  description: string;
}

function CreateAPIKeyPage() {
  const { token } = useSelector(selectUserState);
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [apiKeyLoaded, setApiKeyLoaded] = React.useState<string | null>(null);
  const onSubmit = React.useCallback(
    async (values: CreateAPIKeyFormValues) => {
      setLoading(true);
      if (token && values.displayName?.length) {
        const res = await apiCreateApiKey(token, {
          displayName: values.displayName,
          description: values.description || "",
        });

        if (res.status === 201) {
          setApiKeyLoaded(res.data.apiKey.apiKey);
          message.success("API Key created successfully!");
          // navigate(RouterPaths.API_KEYS);
        } else {
          message.error("Failed to create API Key!");
        }
      }
      setLoading(false);
    },
    [token]
  );

  return (
    <ContentWrapper>
      <Typography.Title level={2}>Create API Key</Typography.Title>
      <Card>
        <Form
          name="createApiKey"
          className={styles.form}
          onFinish={onSubmit}
          disabled={loading}
        >
          <Form.Item
            label="Display Name"
            name="displayName"
            rules={[{ required: true, message: "Please input display name!" }]}
            labelCol={{ span: 24 }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Description"
            name="description"
            labelCol={{ span: 24 }}
          >
            <TextArea style={{ resize: "none" }} />
          </Form.Item>
          <Form.Item labelCol={{ span: 24 }}>
            <Button type="primary" htmlType="submit">
              Create API Key
            </Button>
          </Form.Item>
        </Form>
      </Card>
      <Modal
        open={!!apiKeyLoaded}
        title="Copy your API Key"
        onOk={() => {
          navigate(RouterPaths.API_KEYS);
        }}
        onCancel={() => {
          navigate(RouterPaths.API_KEYS);
        }}
        cancelText={null}
        closable={false}
      >
        <Typography.Paragraph>
          Your API Key has been created successfully. Copy it and keep it safe.
          You wont be able to see it again.
        </Typography.Paragraph>
        <Typography.Paragraph copyable={{ text: apiKeyLoaded as string }}>
          <pre>{apiKeyLoaded}</pre>
        </Typography.Paragraph>
      </Modal>
    </ContentWrapper>
  );
}

export default CreateAPIKeyPage;
