import React from "react";
import styles from "./index.module.scss";
import ContentWrapper from "../../components/ContentWrapper";
import {
  Button,
  Card,
  Dropdown,
  MenuProps,
  Table,
  Typography,
  message,
} from "antd";
import { useDevicesList } from "../../hooks/use-devices-list";
import ContentHeader from "../../components/ContentHeader";
import { Link, useNavigate } from "react-router-dom";
import { RouterPaths } from "../../router/paths";
import { apiDeleteDevice } from "../../services/device";
import { useSelector } from "react-redux";
import { selectUserState } from "../../store/selectors/user";
import { apiDeleteApiKey } from "../../services/api-key";
import { useApiKeys } from "../../hooks/use-api-keys";

function APIKeysPage() {
  const [apiKeysData] = useApiKeys({});
  const { token } = useSelector(selectUserState);
  const [selectedApiKeys, setSelectedApiKeys] = React.useState<React.Key[]>([]);
  const [actionLoading, setActionLoading] = React.useState(false);

  const columns: any = [
    {
      title: "Name",
      dataIndex: "displayName",
      key: "displayName",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (v: any) => new Date(v).toLocaleString(),
    },
    {
      title: "ID",
      dataIndex: "aliasId",
      key: "aliasId",
    },
  ];

  const selected = selectedApiKeys.length > 0;
  const disabled = actionLoading || !selected;
  const isLoading =
    (!apiKeysData.data?.data?.apiKeys && apiKeysData.isLoading) ||
    actionLoading;

  const actions: MenuProps["items"] = [
    {
      key: "Delete",
      label: "Delete",
      onClick: async () => {
        setActionLoading(true);
        if (token && selectedApiKeys) {
          const res = await apiDeleteApiKey(token, {
            apiKeyIds: selectedApiKeys as string[],
          });
          if (res.status === 201) {
            apiKeysData.refetch();
            message.success("API Keys deleted successfully!");
          } else {
            message.error("Failed to delete API Keys!");
          }
        }
        setActionLoading(false);
      },
      disabled: actionLoading || !selected,
    },
  ];

  return (
    <ContentWrapper>
      <Typography.Title level={2}>API Keys</Typography.Title>
      <Card>
        <ContentHeader
          title="List of all API keys"
          actions={
            <>
              <Link
                to={RouterPaths.CREATE_API_KEY}
                style={{ marginRight: "8px" }}
              >
                <Button type="primary">Create API Key</Button>
              </Link>
              <Dropdown menu={{ items: actions }} placement="bottomRight">
                <Button disabled={disabled}>Actions</Button>
              </Dropdown>
            </>
          }
        />
        <Table
          columns={columns}
          dataSource={apiKeysData.data?.data?.apiKeys?.map((d) => ({
            key: d.aliasId,
            ...d,
          }))}
          loading={isLoading}
          rowSelection={{
            selectedRowKeys: selectedApiKeys,
            onChange: (keys) => setSelectedApiKeys(keys),
          }}
          pagination={false}
        />
      </Card>
    </ContentWrapper>
  );
}

export default APIKeysPage;
