import API from "./axios";
import { DeviceApiPaths } from "./paths";
import {
  CreateDeviceRequest,
  CreateDeviceResponse,
  DeleteDeviceRequest,
  DeleteDeviceResponse,
  GetDeviceRequest,
  GetDeviceResponse,
  ListDevicesRequest,
  ListDevicesResponse,
  UpdateDeviceRequest,
  UpdateDeviceResponse,
} from "./types";

export function apiListDevices(token: string, body: ListDevicesRequest) {
  return API.post<ListDevicesResponse>(
    `device/${DeviceApiPaths.LIST_DEVICES}`,
    body,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

export function apiGetDevice(token: string, body: GetDeviceRequest) {
  return API.post<GetDeviceResponse>(
    `device/${DeviceApiPaths.GET_DEVICE}`,
    body,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

export function apiCreateDevice(token: string, body: CreateDeviceRequest) {
  return API.post<CreateDeviceResponse>(
    `device/${DeviceApiPaths.CREATE_DEVICE}`,
    body,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

export function apiDeleteDevice(token: string, body: DeleteDeviceRequest) {
  return API.post<DeleteDeviceResponse>(
    `device/${DeviceApiPaths.DELETE_DEVICE}`,
    body,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

export function apiUpdateDevice(token: string, body: UpdateDeviceRequest) {
  return API.post<UpdateDeviceResponse>(
    `device/${DeviceApiPaths.UPDATE_DEVICE}`,
    body,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
}
