import React from "react";
import ContentWrapper from "../../components/ContentWrapper";
import {
  Button,
  Card,
  Dropdown,
  MenuProps,
  Table,
  Typography,
  message,
} from "antd";
import { useDevicesList } from "../../hooks/use-devices-list";
import ContentHeader from "../../components/ContentHeader";
import { Link } from "react-router-dom";
import { RouterPaths } from "../../router/paths";
import { apiDeleteDevice } from "../../services/device";
import { useSelector } from "react-redux";
import { selectUserState } from "../../store/selectors/user";

function SingleEventPage() {
  const [devices, setDevicesRequest] = useDevicesList({
    defaultBody: { pagination: { limit: 10, offset: 0 } },
  });
  const { token } = useSelector(selectUserState);
  const [selectedDevices, setSelectedDevices] = React.useState<React.Key[]>([]);
  const [actionLoading, setActionLoading] = React.useState(false);

  const columns: any = [
    {
      title: "Name",
      dataIndex: "displayName",
      key: "displayName",
      render: (v: string, r: any) => (
        <Link to={`${RouterPaths.DEVICES}/${r.aliasId}`}>{v}</Link>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (v: any) => new Date(v).toLocaleString(),
    },
    {
      title: "ID",
      dataIndex: "aliasId",
      key: "aliasId",
    },
  ];

  const selected = selectedDevices.length > 0;
  const disabled = actionLoading || !selected;
  const isLoading =
    (!devices.data?.data?.devices && devices.isLoading) || actionLoading;

  const actions: MenuProps["items"] = [
    {
      key: "Delete",
      label: "Delete",
      onClick: async () => {
        setActionLoading(true);
        if (token && selectedDevices) {
          const res = await apiDeleteDevice(token, {
            deviceIds: selectedDevices as string[],
          });
          if (res.status === 201) {
            devices.refetch();
            message.success("Devices deleted successfully!");
          } else {
            message.error("Failed to delete devices!");
          }
        }
        setActionLoading(false);
      },
      disabled: actionLoading || !selected,
    },
  ];

  return (
    <ContentWrapper>
      <Typography.Title level={2}>Devices</Typography.Title>
      <Card>
        <ContentHeader
          title="List of all devices"
          actions={
            <>
              <Link
                to={RouterPaths.CREATE_DEVICE}
                style={{ marginRight: "8px" }}
              >
                <Button type="primary">Add Device</Button>
              </Link>
              <Dropdown menu={{ items: actions }} placement="bottomRight">
                <Button disabled={disabled}>Actions</Button>
              </Dropdown>
            </>
          }
        />
        <Table
          columns={columns}
          dataSource={devices.data?.data?.devices?.map((d) => ({
            key: d.aliasId,
            ...d,
          }))}
          rowSelection={{
            selectedRowKeys: selectedDevices,
            onChange: (keys) => setSelectedDevices(keys),
          }}
          loading={isLoading}
          onChange={(pagination) => {
            setDevicesRequest({
              pagination: {
                limit: pagination.pageSize,
                offset: pagination?.current
                  ? (pagination.current - 1) * (pagination?.pageSize || 10)
                  : 0,
              },
            });
          }}
        />
      </Card>
    </ContentWrapper>
  );
}

export default SingleEventPage;
