import React from "react";
import styles from "./index.module.scss";
import { useUser } from "../../hooks/use-user";
import { Layout, Menu, Typography } from "antd";
import { RouterPaths } from "../../router/paths";
import { useNavigate } from "react-router";
import { ItemType } from "antd/es/menu/hooks/useItems";
import { MenuItemType } from "rc-menu/lib/interface";
import {
  AppstoreAddOutlined,
  AppstoreOutlined,
  DashboardOutlined,
  FileAddOutlined,
  InboxOutlined,
  LockOutlined,
  MenuOutlined,
  PartitionOutlined,
  PlusSquareOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";

function LeftSidebar() {
  const navigate = useNavigate();

  const menuItems: ItemType<MenuItemType>[] = [
    {
      key: RouterPaths.DASHBOARD,
      label: "Dashboard",
      icon: <DashboardOutlined />,
      onClick: () => navigate(RouterPaths.DASHBOARD),
    },
    {
      key: "__" + RouterPaths.PROJECTS,
      label: "Projects",
      icon: <InboxOutlined />,
      children: [
        {
          key: RouterPaths.PROJECTS,
          label: "All projects",
          icon: <UnorderedListOutlined />,
          onClick: () => navigate(RouterPaths.PROJECTS),
        },
        {
          key: RouterPaths.CREATE_PROJECT,
          label: "New project",
          icon: <PlusSquareOutlined />,
          onClick: () => navigate(RouterPaths.CREATE_PROJECT),
        },
      ],
    },
    {
      key: "__" + RouterPaths.DEVICES,
      label: "Devices",
      icon: <PartitionOutlined />,
      children: [
        {
          key: RouterPaths.DEVICES,
          label: "All devices",
          icon: <AppstoreOutlined />,
          onClick: () => navigate(RouterPaths.DEVICES),
        },
        {
          key: RouterPaths.CREATE_DEVICE,
          label: "Add device",
          icon: <AppstoreAddOutlined />,
          onClick: () => navigate(RouterPaths.CREATE_DEVICE),
        },
      ],
    },
    {
      key: "__" + RouterPaths.API_KEYS,
      label: "API Keys",
      icon: <LockOutlined />,
      children: [
        {
          key: RouterPaths.API_KEYS,
          label: "All API Keys",
          icon: <MenuOutlined />,
          onClick: () => navigate(RouterPaths.API_KEYS),
        },
        {
          key: RouterPaths.CREATE_API_KEY,
          label: "Create API Key",
          icon: <FileAddOutlined />,
          onClick: () => navigate(RouterPaths.CREATE_API_KEY),
        },
      ],
    },
  ];

  const findSelectedKeys = (() => {
    const keys: string[] = [];
    const selectedKey = menuItems.find((mi: any) => {
      if (mi?.children) {
        const found = mi.children.find((child: any) =>
          window.location.pathname.includes(
            (child?.key as string).replace("__", "")
          )
        );
        if (found) {
          keys.push(mi.key as string);
          keys.push(found.key as string);
        }
      }
      if (window.location.pathname.includes(mi.key as string)) {
        keys.push(mi.key as string);
      }
    });
    return keys;
  })();

  return (
    <Layout.Sider className={styles.container} width={250}>
      <span className={styles.label}>Navigation</span>
      <Menu
        theme="dark"
        mode="inline"
        defaultOpenKeys={menuItems.map((mi) => mi?.key as string)}
        selectedKeys={findSelectedKeys}
        items={menuItems}
        style={{ flex: 1, minWidth: 0 }}
      />
    </Layout.Sider>
  );
}

export default LeftSidebar;
