import React from "react";
import styles from "./index.module.scss";

interface ContentWrapperProps {
  className?: string;
  children: any;
}

function ContentWrapper({ className = "", children }: ContentWrapperProps) {
  return <div className={`${styles.container} ${className}`}>{children}</div>;
}

export default ContentWrapper;
